import React, { useState } from "react";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const yearAsSeconds = dayjs.duration(1, "year").asSeconds();
const centuryAsSeconds = dayjs.duration(100, "year").asSeconds();

const periods = [];
for (const unit of ["second", "minute", "hour", "day", "week", "month"]) {
  for (let power = 1; power <= 10; power++) {
    const scalar = Math.pow(10, power);
    const d = dayjs.duration(scalar, unit);
    const dAsSeconds = d.asSeconds();
    if (yearAsSeconds < dAsSeconds && dAsSeconds < centuryAsSeconds) {
      periods.push({ scalar, unit, d });
    }
  }
}

export default function App() {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(10, "year").format("YYYY-MM-DD")
  );
  return (
    <div className="App">
      <label htmlFor="start-date">
        Start date (birthday, anniversary, etc.)
      </label>
      <input
        className="border border-black rounded p-1 font-mono w-full"
        id="start-date"
        onChange={(event) => setStartDate(event.target.value)}
        value={startDate}
      />
      <ul>
        {periods
          .map(({ scalar, unit, d }) => {
            let multiplier = 0;
            let nextUp = dayjs(startDate, "YYYY-MM-DD");
            while (nextUp.isBefore(dayjs())) {
              nextUp = nextUp.add(d);
              multiplier += 1;
            }
            return { unit, scalar, multiplier, nextUp };
          })
          .sort((a, b) => (a.nextUp.isBefore(b.nextUp) ? -1 : 1))
          .map(({ unit, scalar, multiplier, nextUp }) => {
            return (
              <li key={unit + scalar}>
                +({multiplier}*{new Intl.NumberFormat("en-US").format(scalar)}){" "}
                {unit} = {nextUp.format("YYYY-MM-DD")}
              </li>
            );
          })}
      </ul>
    </div>
  );
}
